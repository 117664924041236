.App {
  overflow-x: hidden;
}
.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu {
  margin-top: -5px;
}

.inner-menu {
  margin-left: 6em;
}

button.orange {
  width: 170px;
  padding-inline-start: 5px;
  height: 42px;
  background-color: #ff4400;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  outline: none;
  border: none;
  font-size: 18px;
  position: relative;
  line-height: 23.86px;
  span {
    position: absolute;
    right: 20px;
  }
}
button.orange:hover {
  background-color: #f75c24 !important;
}
button.orange:active {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

div.wayapayJumbotron {
  background-color: #fff;
  padding-left: 6%;
  background-repeat: no-repeat;
  background-position-y: 140%;
  > div div:first-of-type {
    align-self: center;
  }
  h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 42px;
    line-height: 116.6%;
    color: #4f4f4f;
  }
  h2 + p {
    font-size: 15px;
    line-height: 160%;
    color: #4f4f4f;
    word-spacing: 3px;
    max-width: 90%;
    font-family: 'Sarabun', sans-serif;
  }
  img {
    max-width: 430px !important;
    margin-left: -90px;
    filter: drop-shadow(0px 2px 2px #4e4c4d9a);
  }
}

.theGrids {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.loading {
  margin-left: 47vw !important;
}

.hiding {
  display: none;
}

.showing {
  display: block;
}

.tou-jumbotron {
  background-repeat: no-repeat;
  background-position: right;
  height: 80% !important;
  h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 57px;
    line-height: 116.6%;
    color: #ff4400;
  }
}

.documentPages {
  article {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 5vh;
  }
}

@media only screen and (max-width: 768px) {
  .theGrids {
    grid-template-columns: repeat(2, 40vw);
  }
}

@media only screen and (max-width: 425px) {
  .theGrids {
    grid-template-columns: 300px;
  }
  div.wayapayJumbotron {
    padding-top: 0;
    background-color: #fff;
    background-image: none !important;
    h2 {
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 23px;
    }
    h2 + p {
      font-size: 13px;
    }
    img {
      max-width: 230px !important;
      text-align: center;
      margin-left: 10px;
    }
  }
}

$font-stack: 'Work Sans', sans-serif;
$font-d1: 'Libre Baskerville';
$font-text: 'Lato', sans-serif;
$primary: #ff4400;
$primary-color: #ff4400;
$secondary-color: #828282;
$secondary-light: #c4c4c4;
$secondary-dark: #2d2b2a;
$primary-link: #0890a3;
$color-dblue: #064a72;

.hover:hover {
  background-color: #ff4400;
  color: #fff0eb;
}

.list-item {
  display: 'inline-block';
  flex-basis: 50%;
  margin-left: '0.5em';
}

.ui.primary.button {
  background: #0e71eb;
  color: #ffffff;
  border-radius: 24px;
  font-family: $font-stack;
  font-style: italic;
  font-weight: normal;
  font-size: 31px;
  line-height: 5px;
}

*:focus {
  outline: none !important;
}

textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  overflow: auto;
}

// #app {
//   height: 100%;
//   min-height: 100vh;
// }

.h-100 {
  height: 100%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.add-cursor {
  cursor: pointer;
}

.text-primary {
  color: $primary-color !important;
}

.primary-link {
  color: $primary-link !important;
}

.custom-blue {
  color: $color-dblue !important;
}

.active {
  color: $primary-color !important;
}

.active-bottom {
  border-bottom: 1px solid $primary-color !important;
}

.btn-primary {
  color: #fff !important;
  background: $primary-color;
  border: none !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-secondary-light {
  color: $secondary-light;
}

.text-secondary-dark {
  color: $secondary-dark;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

// toggle input password
.toggle-pass-wrapper {
  position: relative;
}

.pass-icon {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  right: 0.5rem;
  color: $secondary-light;
  z-index: 2;
}

input:focus,
select:focus {
  outline: none;
}

*:focus {
  outline: none;
}

.btn-primary {
  color: #fff !important;
  background: #ff4400;
  border: none !important;
}
.btn-primary:hover {
  color: #fff !important;
  background: #ff4400 !important;
  border: none !important;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}

.btn-outline-primary:hover {
  color: #fff !important;
}
