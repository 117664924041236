.pricingPages{
    // min-height: 100vh;
    // background-color: ;
    .pricing-top{
        height: 500px;
        background-color: #FF4400;
        background-blend-mode: darken;
        background-repeat: no-repeat;
        overflow-y: visible;
        margin-bottom: 100px;
    }

    // @media screen and(max-width: 500px){
    //     // min-height: 500vh;
    //     .pricing-top{
    //         height: 100%;
    //         margin-bottom: 10px;
    //     }
    // }
}