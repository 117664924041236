.react-responsive-modal-container {
  background: rgba(20, 39, 78, 0.19607843137254902) !important;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.react-responsive-modal-root {
  z-index: 1060 !important;
}

.react-responsive-modal-modal {
  border-top: 4px solid #ff4400;
}

@media (min-width: 400px) {
  .react-responsive-modal-modal {
    width: 500px;
    max-width: 500px !important;
  }
}

@media (max-width: 400px) {
  .react-responsive-modal-modal {
    border-radius: 5px;
    width: 90vw !important;
  }
}
.react-responsive-modal-closeButton {
  display: none;
}

.modal-body-rs {
  // width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

.header-sec-modal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}

.header-img1 {
  width: 13px;
  height: 16px;
  cursor: pointer;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .check-input[type='checkbox'],
  .check-input[type='radio'] {
    --active: #717373;
    --active-inner: #fff;
    --focus: 1px #7173737e;
    --border: #717373;
    --border-hover: #717373;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  .check-input[type='checkbox']:after,
  .check-input[type='radio']:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }
  .check-input[type='checkbox']:checked,
  .check-input[type='radio']:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  .check-input[type='checkbox']:disabled,
  .check-input[type='radio']:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  .check-input[type='checkbox']:disabled:checked,
  .check-input[type='radio']:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  .check-input[type='checkbox']:disabled + label,
  .check-input[type='radio']:disabled + label {
    cursor: not-allowed;
  }
  .check-input[type='checkbox']:hover:not(:checked):not(:disabled),
  .check-input[type='radio']:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  .check-input[type='checkbox']:focus,
  .check-input[type='radio']:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  .check-input[type='checkbox']:not(.switch),
  .check-input[type='radio']:not(.switch) {
    width: 21px;
  }
  .check-input[type='checkbox']:not(.switch):after,
  .check-input[type='radio']:not(.switch):after {
    opacity: var(--o, 0);
  }
  .check-input[type='checkbox']:not(.switch):checked,
  .check-input[type='radio']:not(.switch):checked {
    --o: 1;
  }
  .check-input[type='checkbox'] + label,
  .check-input[type='radio'] + label {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }
  .check-input[type='checkbox']:not(.switch) {
    border-radius: 7px;
  }
  .check-input[type='checkbox']:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }
  .check-input[type='checkbox']:not(.switch):checked {
    --r: 43deg;
  }
  .check-input[type='checkbox'].switch {
    width: 38px;
    border-radius: 11px;
  }
  .check-input[type='checkbox'].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  .check-input[type='checkbox'].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  .check-input[type='checkbox'].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }
  .check-input[type='radio'] {
    border-radius: 50%;
  }
  .check-input[type='radio']:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  .check-input[type='radio']:checked {
    --s: 0.5;
  }
}
.checboxWrap {
  margin: 0;
  padding: 0;
  list-style: none;
}
.checboxWrap li {
  margin: 0;
  position: relative;
}

.option-one-header {
  display: flex;
  height: 30px;
}

.option-one-header > * {
  margin-right: 15px;
}

.option-one-header img {
  width: 55px;
  height: 24px;
}

.paymentOptions {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 10px;
}

.order-summary-modal {
  width: 100%;
  border-radius: 4px;
  padding: 10px;
}

.modal-header-data {
  font-size: 25px;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 10px;
  color: #3a3837;
  font-weight: bold;
}

.option-one-header p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #4f4f4f;
}

.option-one-header-body p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  color: #717373;
}

.waya-transation-summary-modal {
  width: 100%;
}

.summary-data-waya-modal {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.summary-data-waya-modal > * {
  margin-bottom: 10px;
}

.summary-data-waya-modal span:last-child {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #f15f3f;
}

.submit-modal-btn-wrap {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.submit-modal-btn-wrap button {
  width: 100%;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  background: #ff4400;
  border-radius: 4px;
  color: white;
  border: none;
}

.order-summary-data {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.order-summary-data h1 {
  white-space: nowrap;
  width: 90%;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;

  color: #3a3837;
}
.order-summary-amount-modal {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  padding: 10px 10px 10px 10px;
}

.order-summary-amount-modal span:last-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #f15f3f;
}

.waya-modal-body {
  width: 100%;
}

.m-t-15 {
  margin-top: 15px;
}

.proceed-payment-terms span {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.proceed-payment-terms span span {
  font-weight: normal;
  font-size: 12px;
  color: #4f4f4f;
  margin-top: 0;
  margin-left: 5px;
  white-space: nowrap;
}

.proceed-payment-terms span span a {
  color: #54b2c0;
  margin-left: 2px;
  white-space: nowrap;
}

.inputbox-with-one-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.inputbox-with-one-input label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0d0d0d;
}

.inputbox-with-one-input input,
select {
  border: none !important;
  font-size: 16px;
  line-height: 25px;
  // color: #b6b3b3 !important;
  border-bottom: 1px solid #c4c4c4 !important;
  padding-bottom: 6px;
  border-radius: 0 !important;
}

.inputbox-with-one-input select,
input {
  padding-left: 0 !important;
}

.inputbox-with-two-input {
  display: flex;
  justify-content: space-between;
}

.inputbox-with-two-input > div {
  width: 45%;
}

.modal-header-sub-data {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 17px;
  color: #4f4f4f;
  text-align: center;
}

.inputbox-with-OTP-code {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.inputbox-with-OTP-code input {
  width: 80px;
  font-size: 16px;
  border: none;
  line-height: 25px;
  color: #b6b3b3;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 6px;
}

.transation-digin-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.transation-digin-info span {
  font-size: 16px;
  line-height: 135.6%;
  text-align: center;
  color: #4f4f4f;
  font-weight: 400;
}

.transation-digin-info span:last-child {
  font-weight: 600;
}

.transation-qr-code-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

// Login section

#login-modal {
  .waya-modal-body {
    margin-top: 50px;
    margin-bottom: 200px !important;
  }
  span {
    font-size: 13px;
  }
}

// forgot password section

#forgot-password-modal {
  .waya-modal-body {
    margin-top: 50px;
  }
}
#forgot-password-modal {
  .inputbox-with-one-input {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .back {
    margin-top: 40px;
  }

  .modal-footer {
    margin-top: 100px !important;
    margin-bottom: 50px;
  }
}

// reset password
#reset-password-modal {
  span {
    text-align: center;
  }
  .inputbox-with-one-input {
    margin-top: 20px;
  }
  .confirm-password {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .back {
    margin-bottom: 40px;
  }

  .modal-footer {
    margin-top: 150px !important;
    margin-bottom: 50px;
  }
}

// pin modal
#pin-modal {
  .react-responsive-modal-modal {
    border-top: none;
    border-radius: 7px;
    // width: 400px;
  }
  .waya-modal-body {
    margin-bottom: 50px;
    width: 100%;
  }
}

// signup section

.reg-active {
  background: #b6b3b33b;
}

.termsnconditionText {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #828282;
  width: 50%;
}
