.main-jumbotron{
    padding-left: 6%;
   h1{
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 50px;
    line-height: 116.6%;
    color: #4F4F4F;
   }
   h1+p{
    font-size: 15px;
    line-height: 160%;
    color: #4F4F4F;
    font-family: 'Sarabun', sans-serif;
   }
   strong{
    color:#4F4F4F;
    font-family: 'Sarabun', sans-serif;
   }
}
.main-jumbotron strong + div.row{

    > img {
        height: 42.5px;
        max-width: 170px !important;
       
    }
}

.main-jumbotron img[alt='jumbotron'] {
    max-width: 85%; 
}

.innerBanner{
    h3{
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 26px;
        line-height: 116.6%;
        color: #4F4F4F;
        text-transform: uppercase;
    }
}

.innerBanner:nth-of-type(3){
    background-color: #F9F8F7;
    div.row > div {
        align-self:center;
    }
    img{
        max-width: 450px !important; 
    }
}
.innerBanner:nth-of-type(4){
    div.row > div {
        align-self:center;
    }
    img{
        max-width: 70% !important; 
    }
}

@media only screen and (max-width: 425px){
    #merchantPage{
        .main-jumbotron{
            margin-bottom: 80px !important;
           h1{
            font-size: 28px;
            padding-right: 1px;
           }
           h1+p{
            font-size: 13px;
           }
           strong{
               position: absolute;
               top: 82vh;
           }
           strong+div{
                position: absolute;
                top: 85vh;
           }
        }
        .innerBanner{
            padding-left: 15px;
            padding-right: 15px;
            h3{
                font-size: 19px;
            }
            p{
                font-size: 13px;
                line-height: 160%;
            }
            div.row{
                >img:nth-child(1) {
                    order:5;
                    max-width: 70%;
                }
                >div:nth-of-type(1){
                    order: 4;
                   
                }
                >img:nth-child(2) {
                    order:5;
                    max-width: 70% !important;
                }
                >div:nth-of-type(1){
                    order: 4;
                   
                }
            }
            .except{
                h3{
                    padding-top: 5vh !important;
                }
            }
            div.row.except{
                margin-top: 10%;
                h3{
                    position: absolute;
                    top: -36vh;
                }
                >img:nth-child(1) {
                    order:2;
                    max-width: 70%;
                }
                >div:nth-of-type(1){
                    order: 4;
                   
                }
                img:nth-of-type(2){
                    //max-width: 40% !important;
                }
                img[alt='Google Play Download']{
                    //max-width: 40% !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 375px){
    .main-jumbotron{
         img[alt~='Download'] {
            max-width: 130px !important;
        }
    }
}

@media only screen and (max-width: 375px) and (height: 812px){
    #merchantPage {
        .main-jumbotron{
           strong{
               position: absolute;
               top: 59vh;
           }
           strong+div{
                position: absolute;
                top: 62vh;
           }
        }
    }
}